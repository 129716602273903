import { AscDataLayerEvent } from './events';

export type AscDataLayer = Record<string, any> & {
    events?: Record<string, any>[]
    items?: AscItem[]
    page_type?: string
    affiliation?: string
};

export type AscItem = Record<string, any> & {
    item_category?: any
    item_fuel_type?: any
    item_inventory_date?: any
    item_color?: any
    item_simple_color?: any
    item_payment?: any
    item_condition?: any
    item_year?: any
    item_make?: any
    item_model?: any
    item_type?: any
    item_variant?: any
    item_id?: any
    item_number?: any
    item_price?: any
};

declare global {
    interface Window {
        asc_datalayer?: AscDataLayer,
    }
}

let lastEventCount: number|undefined = undefined;
let lastDataLayerCaptured: Record<string, any> = {};

const removeEmptyValues = (obj: Record<string, any>) => {
    return Object.fromEntries(
        Object.entries(obj).filter(([_, v]) => (v !== null && v !== ''))
    );
};

export const reduceDataLayerItems = (items: AscItem[]) => {
    if (items.length === 0) {
        return {};
    }

    if (items.length === 1) {
        return removeEmptyValues({ ...items[0] });
    }

    const firstItem = { ...items[0] };
    const reduced = items.slice(1).reduce((acc, item) => {
        for (const key in item) {
            if (acc[key] !== item[key]) {
                // Null when there are conflicting values.
                acc[key] = null;
            }
        }

        for (const key in acc) {
            if (item[key] === undefined) {
                // Null when there are conflicting values.
                acc[key] = null;
            }
        }

        return acc;
    }, firstItem);

    return removeEmptyValues(reduced);
}

const isEqual = (a: Record<string,any>, b: Record<string,any>) => {
    return Object.keys(a).every((key) => a[key] === b[key]) && 
           Object.keys(b).every((key) => a[key] === b[key]);
}

export const getDataLayerSnapshot = () => {
    if (!window.asc_datalayer) {
        return {};
    }

    let capture: Record<string, any>;
    capture = reduceDataLayerItems(window.asc_datalayer.items || []);    
    capture.page_type = window.asc_datalayer.page_type;
    capture.affiliation = window.asc_datalayer.affiliation;

    // --------------------------------------------------------------
    // These fields could be available, but not currently used by us
    // --------------------------------------------------------------
    //   capture.currency = window.asc_datalayer.currency;
    //   capture.language = window.asc_datalayer.language;
    //   capture.oem_code = window.asc_datalayer.oem_code;
    //   capture.oem_brand = window.asc_datalayer.oem_brand;
    //   capture.store_name = window.asc_datalayer.store_name;

    return capture;
};

const captureAscDataLayer = () => {
    if (!window.asc_datalayer) {
        return;
    }
    
    const capture = getDataLayerSnapshot();
    if (isEqual(capture, lastDataLayerCaptured)) {
        return;
    }

    lastDataLayerCaptured = capture;
    window.dispatchEvent(new CustomEvent<AscDataLayerEvent>('800dni.asc-datalayer', {
        detail: {
            dataLayer: capture,
        },
    }));
};

const checkForNewAscEvents = () => {
    const eventCount = window.asc_datalayer?.events?.length || 0;
    if (eventCount === lastEventCount) {
        return;
    }

    // We've had new events since the last data layer capture
    // so it's possible the other data in data_layer has changed.
    lastEventCount = eventCount;
    captureAscDataLayer();
}

export const initAscDataLayerMonitoring = () => {
    setTimeout(() => {
        captureAscDataLayer();
        lastEventCount = window.asc_datalayer?.events?.length || 0;
        setInterval(checkForNewAscEvents, 500);
    }, 500);
};

