import {startWatching} from "./dom-watcher";
import {CallTrackingResponse, Swap, SwapItem, SwapResponse} from "../types";

export type CallTrackingRepository = () => Promise<CallTrackingResponse>;

export type PageContext = {
    url: URL
    referrer: string
    landingPage: URL
    originalReferrer: string
    source: string
    isNewContext: boolean
}

export type PhoneNumberRenderer = (
    node: Node,
    swaps: SwapItem[],
) => void;


export type DniCache = {
    set<T>(key: string, data: T, ttl?: number): void;
    get<T>(key: string): T | null
}

export default class App {
    private static instance: App;
    private readonly callTracking: CallTrackingRepository;
    private readonly renderer: PhoneNumberRenderer;

    private constructor(
        callTracking: CallTrackingRepository,
        phoneNumberRenderer: PhoneNumberRenderer,
    ) {
        this.callTracking = callTracking;
        this.renderer = phoneNumberRenderer;
    }

    public static getInstance(
        callTracking: CallTrackingRepository,
        phoneNumberRenderer: PhoneNumberRenderer,
    ): App {
        if (!App.instance) {
            App.instance = new App(callTracking, phoneNumberRenderer);
        }

        return App.instance;
    }

    public run(): App {
        startWatching(async (node) => {
            const response = await this.callTracking();

            if (response?.swap) {
                this.renderer(node, [response.swap]);
            }

            if (response?.swaps) {
                this.renderer(node, response.swaps);
            }
        });

        return this;
    }
}