import {CallTrackingResponse} from '../../types';
import {DniCache, CallTrackingRepository} from '../app';
import {error} from '../debugging';

const SESSION_KEY = '800cltrk.session';

type CallTrackingOptions = {
    storage: DniCache,
    url: string,
    landingPage: string,
    referrer: string | null,
    source: string | null,
    isNewPageContext: boolean,
}

type SessionCache = {
    sessionId: string;
}

export function initCallTracking(options: CallTrackingOptions): CallTrackingRepository {
    const init = async (sessionId?: string): Promise<CallTrackingResponse> => {
        const response : Response = await fetch(options.url, {
            method: 'POST',
            body: JSON.stringify({
                sessionId,
                landingPage: options.landingPage,
                referrer: options.referrer,
                source: options.source,
                date: new Date().toISOString(),
                isNewPageContext: options.isNewPageContext,
            }),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
        });

        if (response.status !== 200) {
            error(await response.text());

            throw new Error('Received non-200 response while fetching swaps');
        }

        const payload = await response.json() as {data: CallTrackingResponse};

        return payload.data;
    };

    const existingSession = options.storage.get<SessionCache>(SESSION_KEY);

    const results = init(existingSession?.sessionId);

    results.then(({ sessionId }) => {
        options.storage.set<SessionCache>(SESSION_KEY, { sessionId }, (86400 * 365));
    });

    return async () => results;
}
