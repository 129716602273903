const prefix: string = '[800js] 🚀';

// @todo: all log entries get pushed into an array that can be pushed to backend for telemetry

function debug(...args: any[]): void {
    if (!isDebugging()) return;

    console.debug(...args);
}

function info(...args: any[]): void {
    if (!isDebugging()) return;
    
    console.info(...args);
}

function warn(...args: any[]): void {
    if (!isDebugging()) return;
    console.warn(...args);
}

function error(...args: any[]): void {
    if (!isDebugging()) return;
    console.error(...args);
}

function isDebugging(): boolean {
    return document?.cookie?.indexOf('_800dbg') !== -1 ||
        window?._800js?.debug === true;
}

export { debug, info, warn, error, isDebugging };