const clientPattern = new RegExp(/(^|\s)_ga=G[AS]\d\.\d\.(\d+)\.(\d*?)(;|$)/);

const sessionPattern =  new RegExp(/(^|\s)_ga_(\w+)=GS\d\.\d\.(\d+)\.(\d+)\.\d+\.\d+\.\d+\.0\.0(;|$)/g);

function initClientTracking(): void {
    const matches = document.cookie.match(clientPattern);

    if (matches) {
        window.dispatchEvent(new CustomEvent(
            '800dni.ga-client',
            {
                detail: {
                    clientId: matches[2],
                    clientNumber: matches[3],
                }
            }
        ))

        return;
    }

    setTimeout(initClientTracking, 500);
}

function initSessionTracking(foundSessions: string[]): void {
    const matches = document.cookie.matchAll(sessionPattern)

    const newFoundSessions: string[] = [];

    for (const match of matches) {
        const [,, sessionName, sessionId, sessionNumber] = match;

        if  (!foundSessions.includes(sessionName)) {
            newFoundSessions.push(sessionName);

            window.dispatchEvent(new CustomEvent(
                '800dni.ga-session',
                {
                    detail: {
                        sessionName,
                        sessionId,
                        sessionNumber,
                    }
                }
            ));
        }
    }

    setTimeout(() => {
        initSessionTracking([...foundSessions, ...newFoundSessions]);
    }, 500);
}

export function initGoogleAnalytics(): void {
    initClientTracking();
    initSessionTracking([]);
}