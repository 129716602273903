import {debug} from "./debugging";

type DomReaderCallback = (node: Node) => void

const phoneNumberRegex = /(?:(?:\+?([1-9]|[0-9][0-9]|[0-9][0-9][0-9])\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([0-9][1-9]|[0-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?/g

const nodeContainsPhoneNumber = (node: Node): boolean => {
    if ((node as HTMLElement).innerHTML?.includes('tel:')) {
        return true;
    }

    if (node.textContent?.trim() === '') {
        return false;
    }

    if (! node.textContent?.match(/\d/)) {
        return false;
    }
    
    // if (! node.textContent?.match(phoneNumberRegex)) {
    //     return false;
    // }

    return true;
}

const getNodesWithSwapPotential = (node: Node): Node[] => {
    const nodes: Node[] = [];

    // -----------------------------
    // #1: Process the current node
    // -----------------------------
    
    if (node.parentElement instanceof HTMLScriptElement) {
        return nodes;
    }

    if (node.nodeType === Node.TEXT_NODE) {
        // if (node.nodeValue?.match(phoneNumberRegex)) {
            return [node];
        // }

        return nodes;
    }
    
    if (node instanceof HTMLAnchorElement /* &&
        node.getAttribute('href')?.match(phoneNumberRegex) */) {
        nodes.push(node);
    }

    // -----------------------------
    // #2: Process child nodes
    // -----------------------------
    
    if (!nodeContainsPhoneNumber(node)) {
        return nodes;
    }

    for (let i= 0; i < node.childNodes.length; i++) {
        nodes.push(...getNodesWithSwapPotential(node.childNodes.item(i)));
    }

    return nodes;
}

const getUnprocessedNodeFilter = (cache: WeakSet<WeakKey>) => {
    return function(node: Node): boolean {
        // debug('Swapper.observeNode', node);

        // #1: Check if node is already in cache
        if (cache.has(node)) {
            // debug('Swapper.observeNode: node already in cache', node);
            return false;
        }

        // #2: Add node to cache
        // debug('Swapper.observeNode: observed node first time', node);
        cache.add(node);
        return true;
    };
}

const logMutationText = (node: Node): Node => {
    debug('[mutation] Detected text node:', node.textContent);
    return node;
}

export const startWatching = (callback: DomReaderCallback) => {
    const cache = new WeakSet<WeakKey>();

    const nodeFilter = getUnprocessedNodeFilter(cache);

    const observer = new MutationObserver((mutations: MutationRecord[]) => {
        debug("Observed mutation");

        mutations.filter((mutation) => mutation.addedNodes.length !== 0)
            .forEach((mutation) => {
                Array.from(mutation.addedNodes)
                    .map((node) => {
                        getNodesWithSwapPotential(node)
                            .filter(nodeFilter)
                            .forEach(callback);
                    });
            });
    });

    document.addEventListener('DOMContentLoaded', function onDomContentLoaded() {
        // Perform walk on domcontentloaded (todo: DRY)
        debug('DOMContentLoaded: performing walk');

        const nodes = getNodesWithSwapPotential(document.body);

        debug('DOMContentLoaded: walk discovered', nodes.length, 'nodes');

        nodes.filter(nodeFilter).forEach(callback);
    });

    debug('Document Ready State:', document.readyState)
    if (document.readyState === 'complete' || document.readyState === 'interactive') {
        getNodesWithSwapPotential(document.body).filter(nodeFilter).forEach(callback);
    }

    debug('observing changes');
    observer.observe(document, {
        childList: true,
        subtree: true,
        characterData: true,
    });
}