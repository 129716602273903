import {FormattedPhoneNumber, nonVanityPhoneNumberPattern, phoneNumberPattern} from "./replacement-renderer";

export function buildSwapTarget(number: string) {
    if (number.startsWith('+1')) {
        return number;
    }

    if (number.startsWith('1')) {
        return '+' + number;
    }

    return '+1' + number;
}

export function reformatPhoneNumber(targetFormat: string, phoneNumber: string): string {
    const replacementChars = phoneNumber.split('');
    const originalChars = targetFormat.split('').filter(c => c !== "\n");

    if (targetFormat.match(/([a-zA-Z])/)) {
        return reformatPhoneNumber('(000) 000-0000', phoneNumber);
    }

    for (let i = originalChars.length; i > 0; i--) {
        if (originalChars[i - 1] !== ' ' && !isNaN(originalChars[i - 1] as unknown as number)) {
            originalChars[i - 1] = replacementChars.pop() as unknown as string;
        }
    }

    return originalChars.join('');
}

export function findAllPhoneNumbers(text: string): FormattedPhoneNumber[] {
    const matches = text.matchAll(phoneNumberPattern);

    const formattedPhoneNumbers: FormattedPhoneNumber[] = [];

    for (let parts of text.matchAll(nonVanityPhoneNumberPattern)) {
        const [originalFormat, single_digit, npa, npa_or_nxx, nxx, last_four] = parts;

        formattedPhoneNumbers.push({
            originalFormat,
            raw: [single_digit, npa, npa_or_nxx, nxx, last_four].join(''),
        });
    }

    for (let parts of matches) {
        const [originalFormat, single_digit, npa, npa_or_nxx, nxx, last_four, x, y] = parts;

        formattedPhoneNumbers.push({
            originalFormat,
            raw: [single_digit, npa, npa_or_nxx, nxx, last_four, x, y].join(''),
        });
    }

    return formattedPhoneNumbers;
}

/**
 * @see https://github.com/thiagodp/get-xpath/blob/master/src/index.ts
 *
 * Their bundle size is 24kb, just copying this over to avoid that overhead.
 */
export type Options = {
    ignoreId: boolean
};

const defaultOptions: Options = {
    ignoreId: false
};

export function getXPath( el: any, customOptions?: Partial< Options > ): string {
    const options = { ...defaultOptions, ...customOptions };
    let nodeElem = el;
    if ( nodeElem && nodeElem.id && ! options.ignoreId ) {
        return "//*[@id=\"" + nodeElem.id + "\"]";
    }
    let parts: string[] = [];
    while ( nodeElem && Node.ELEMENT_NODE === nodeElem.nodeType ) {
        let nbOfPreviousSiblings = 0;
        let hasNextSiblings = false;
        let sibling = nodeElem.previousSibling;
        while ( sibling ) {
            if ( sibling.nodeType !== Node.DOCUMENT_TYPE_NODE &&
                sibling.nodeName === nodeElem.nodeName
            ) {
                nbOfPreviousSiblings++;
            }
            sibling = sibling.previousSibling;
        }
        sibling = nodeElem.nextSibling;
        while ( sibling ) {
            if ( sibling.nodeName === nodeElem.nodeName ) {
                hasNextSiblings = true;
                break;
            }
            sibling = sibling.nextSibling;
        }
        let prefix = nodeElem.prefix ? nodeElem.prefix + ":" : "";
        let nth = nbOfPreviousSiblings || hasNextSiblings
            ? "[" + ( nbOfPreviousSiblings + 1 ) + "]"
            : "";
        parts.push( prefix + nodeElem.localName + nth );
        nodeElem = nodeElem.parentNode;
    }
    return parts.length ? "/" + parts.reverse().join( "/" ) : "";
}