import uuid4 from 'uuid4';
import {DniCache} from "./app";

class EventStorage {
    private events: any[] = [];

    push(e: any) {
        this.events.push(e)
    }

    drain(): any[] {
        const events = this.events;

        this.events = [];

        return events;
    }
}

export const initEventTracker = (url: string, token: string, sessionId: string) => {
    const eventStorage = new EventStorage();

    const pushDecoratedEvent = (kind: string) => (e: CustomEvent<any>) => {
        eventStorage.push({
            ...e.detail,
            date: new Date().toISOString(),
            kind,
        })
    }

    window.addEventListener('800dni.asc-datalayer', pushDecoratedEvent('asc_datalayer'));
    window.addEventListener('800dni.asc-event', pushDecoratedEvent('asc_event'));
    window.addEventListener('800dni.ga-session', pushDecoratedEvent('ga_session'));
    window.addEventListener('800dni.ga-client', pushDecoratedEvent('ga_client'));
    window.addEventListener('800dni.page_view', pushDecoratedEvent('page_view'));
    // don't think we want to send this. Doesn't seem like a useful event.
    // window.addEventListener('800dni.swap', pushDecoratedEvent('swap'));
    window.addEventListener('800dni.error', pushDecoratedEvent('error'));

    const send = () => {
        setTimeout(() => {
            const events = eventStorage.drain();

            if (events.length > 0) {
                fetch(`${url}/dniEvents`, {
                    method: 'POST',
                    body: JSON.stringify({
                        token,
                        events,
                        sessionId,
                    }),
                    headers: new Headers({
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                    })
                })
                    .then(() => {})
                    .catch((err) => {
                        // noop? We can't report the error.
                        console.error(err);
                    })
            }

            send();
        }, 100);
    }

    send();
}