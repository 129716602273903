export type EventMap = {
    "800dni.cache.miss": CustomEvent<CacheMissEvent>,
    "800dni.cache.hit": CustomEvent<CacheHitEvent>,
    "800dni.cache.prune": CustomEvent<CachePruneEvent>,
    "800dni.cache.set": CustomEvent<CacheSetEvent>,
    "800dni.page_view": CustomEvent<PageViewEvent>,
    "800dni.swap": CustomEvent<SwapEvent>,
    "800dni.error": CustomEvent<ErrorEvent>,
    "800dni.ga-client": CustomEvent<GoogleAnalyticsClientIdFound>,
    "800dni.ga-session": CustomEvent<GoogleAnalyticsSessionFound>,
    "800dni.asc-event": CustomEvent<AscEvent>,
    "800dni.asc-datalayer": CustomEvent<AscDataLayerEvent>,
} & WindowEventMap;

// ASC (Automotive Standards Council)
// We capture data from window.asc_datalayer.
export type AscDataLayerEvent = {
    dataLayer: object,
}

// ASC (Automotive Standards Council)
// Events that we've fired from the ASC standard.
export type AscEvent = {
    eventName: string,
    eventData: object,
}

export type CacheMissEvent = {
    key: string,
}

export type CacheHitEvent = {
    key: string,
}

export type CachePruneEvent = {
    key: string,
}

export type CacheSetEvent = {
    key: string,
    data: any,
}

export type PageViewEvent = {
    url: string
    referrer: string | null
}

export type GoogleAnalyticsClientIdFound = {
    sessionId: string
    sessionNumber: string
}

export type GoogleAnalyticsSessionFound = {
    sessionName: string
    sessionId: string
    sessionNumber: string
}

export type SwapEvent = {
    node: Node
    replacement: string
    replacementE164: string
    selector: string
    original: string
    originalE164: string
}

export type ErrorEvent = {
    //
}

declare global {
    interface Window {
        addEventListener<K extends keyof EventMap>(type: K, listener: (this: Document, ev: EventMap[K]) => void): void;
    }
}