import App, {
    PageContext,
    LocalStorage,
    initCallTracking,
    renderReplacement,
    error,
    info,
    isDebugging,
    PageViewEvent, debug,
    DniCache,
} from './lib';

import {initAscClickToCall} from './lib/asc-click-to-call';
import {initAscDataLayerMonitoring} from './lib/asc-data-layer';
import {initEventTracker} from './lib/event-tracker';
import {resolveSource} from './lib/source-resolver';
import {initGoogleAnalytics} from './lib/ga';

declare global {
    interface Window {
        _800js: {
            debug?: boolean
            ttl?: number
        };
    }
}

window._800js ||= {};

const getPageContext = (storage: DniCache): PageContext => {
    const pageContext: PageContext = {
        url: new URL(window.location.href),
        landingPage: new URL(window.location.href),
        referrer: document.referrer,
        originalReferrer: document.referrer,
        isNewContext: true,
        source: resolveSource({
            url: window.location.href,
            referrer: document.referrer,
        }),
    };
    
    const cached = storage.get<string>('800dni.context');

    if (cached) {
        const cachedPageContext = JSON.parse(cached);
        pageContext.landingPage = cachedPageContext.landingPage
            ? new URL(cachedPageContext.landingPage)
            : new URL(cachedPageContext.url);

        pageContext.originalReferrer = cachedPageContext.originalReferrer || cachedPageContext.referrer;
        pageContext.isNewContext = false;

        if (  pageContext.source !== 'direct' &&   // exclude direct, too many false positives
            ! pageContext.source.includes('.') &&  // exclude internal and unknown external sources (hostnames)
              pageContext.source !== cachedPageContext.source) {
            // We consider this a new context, as the user has arrived from a different source
            pageContext.isNewContext = true;
        }
    }

    const serialized = JSON.stringify(pageContext);
    const storeInCache = () => {
        storage.set('800dni.context', serialized, 3600);
    };

    // We want to expire the cache after 1 hour of inactivity, such that a 
    // new visit is treated separately for the purposes of attribution.
    // Re-setting the value periodically ensures that the TTL is respected for SPA's.
    setInterval(storeInCache, 60_000);
    storeInCache();

    return pageContext;
};

const script = document.currentScript;

const current = script
    ? new URL((script as HTMLScriptElement).src)
    : null;

const token = current?.searchParams.get('token') || String(process.env.PUBLIC_API_TOKEN || script?.getAttribute('data-token')) ;
const url =  current?.searchParams.get('backend') ||String(process.env.PUBLIC_API_ENDPOINT || script?.getAttribute('data-backend'))
    // remote trailing slash because we need to build different URL paths
    .replace(/\/$/, '');

if (!url) {
    error('missing url');
}

if (url) {

    const storage = new LocalStorage(window._800js.ttl || 3600);
    const pageContext = getPageContext(storage);

    const callTracking = initCallTracking({
        storage,
        url: `${url}/calltrk/${token}`,
        landingPage: pageContext.landingPage.toString(),
        referrer: pageContext.originalReferrer,
        source: pageContext.source,
        isNewPageContext: pageContext.isNewContext,
    });

    info('booting', window._800js);

    initAscClickToCall();

    const app = App.getInstance(
        callTracking,
        renderReplacement,
    )
        .run();

    debug('started app');

    if (isDebugging()) {
        Object.assign(window._800js, {app});
    }

    (async () => {
        const sessionId = (await callTracking())?.sessionId;

        debug({sessionId})

        if (!sessionId) {
            return;
        }

        initEventTracker(url, token, sessionId);
        initGoogleAnalytics();
        initAscDataLayerMonitoring();

        window.dispatchEvent(
            new CustomEvent<PageViewEvent>('800dni.page_view', {
                detail: {
                    url: pageContext.url.toString(),
                    referrer: pageContext.referrer || null,
                },
            })
        )
    })();
}
